.wrapper {
  position: relative;
  width: 100%;
}

.input {
  border: 1px solid #efefef;
  padding: 7px 10px 7px 50px;
  border-radius: 5px;
  width: 100%;
  outline: none;
  font-size: 14px;
}

.icon {
  position: absolute;
  top: 7px;
  left: 20px;
  color: #9a9a9a;
}
