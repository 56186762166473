.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1031;
    background: rgba(0, 0, 0, 0.75);
}

.block {
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    width: auto;
}

.title {
    text-align: center;
    font-family: var(--font-main);
    color: #2E1747;
    font-weight: bold;
    margin-bottom: 16px;
}

.row {
    margin-bottom: 16px;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}