.block {
  position: relative;
  border-radius: 8px; 
  min-width: 500px; 
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.08);
  user-select: none;
}
.block_full { 
  width: 100%;
  height: 100vh;
}

.withoutVideo {  
  .videoBlock {
    position: absolute;    
    z-index: -1;
    height: 50px;
    opacity: 0;
    overflow: hidden;
  } 
  .controlBlock {
    border-radius: 8px;
    box-shadow: 2px 4px 16px 0px #00000014;

    &>div {
      display: flex;      
    }    
  }  
  .textBlock {
    display: flex;
    flex-direction: column-reverse;    
  }
  .avatarBlock {    
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border: 3px solid #428DFF;
    border-radius: 50%;
    overflow: hidden;    
    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }     
  }
  .btnBlock {
    margin-left: auto;
    margin-right: 0;
  } 
}

.withVideo {
  .videoBlock {
    position: relative;
    opacity: 1;
    transition: 1c;
    overflow: hidden;  
    .videoLocal {      
      position: absolute;
      bottom: 0;
      right: 0;
      width: 200px;
      height: 118px;      
      border-radius: 8px 0px 0px 0px;
      overflow: hidden;
      // transition: 1s;           
      video {
        width: 100%;                
      }            
    }
    .border {
      border-top: 1px solid #428DFF;
      border-left: 1px solid #428DFF;           
    }
    .cameraOff {
      border: none;
      opacity: 0; 
      video {
        opacity: 0;                
      }      
    }   
    
    .videoRemote {
      position: relative;
      overflow: hidden;      
      height: auto;
      width: 500px;      
      min-height: 272px;
      max-height: 375px;  
      border-radius: 8px 8px 0px 0px;
     
      video {        
        width: 100%;                        
      }       
    }    
    
    
    .videoRemoteAvatar {
      width: 160px;
      height: 160px;
      margin-top: -80px;
      margin-left: -80px;
    }
  }
  .controlBlock {
    justify-content: space-between;    
    border-radius: 0 0 8px 8px;
    
  }  
}

.videoBlock {
  opacity: 1;
  transition: 1c; 
}

.videoRemote {
  position: relative;
  background: linear-gradient(0deg, #505050, #505050), #CEE1FF;
  line-height: 0;
  
  .video {
    position: relative;
    z-index: 2;
    bottom: 0;
  }   
}

.videoRemoteAvatar {
  position: absolute;
  top: 50%;
  left: 50%;
 
  box-sizing: border-box;
  border: 3px solid #428DFF;
  border-radius: 50%;
  z-index: 1;     
}

.videoLocal {
  background: linear-gradient(0deg, #505050, #505050), #CEE1FF;
  line-height: 0;
  z-index: 3; 
}

.controlBlock {  
  display: flex;   
  align-items: center;
  width: 100%;   
  padding: 12px;  
  background: rgba(230, 239, 251, 0.9);
   
}

.nameBlock {
  font-weight: 500; 
}

.statusBlock {  
  font-size: 14px;  
  line-height: 20px; 
  color: #828282;
}

.btnBlock {
  display: flex;  
  min-width: 106px;
  width: auto; 
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;  
    margin: auto;
    padding: 8px;
    cursor: pointer;    
    border-radius: 50%;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  .video {
    background-color: #AAAAAA;
  }
  .notVideo {
    background-color: #FFFFFF;  
  }
  .call {
    background-color: #EB5757; 
  }

  .call_active {
    background-color: #428DFF;  
  }
  .audio {
    background-color: #AAAAAA;
  }  
  .notAudio {
    background-color: #FFFFFF; 
  }
}

.fullscreenOffBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 9px;
  background-color: #FFFFFF;    
  cursor: pointer;  
  border-radius: 8px;
  &:hover {
    path {
      fill: #505050; 
    }
  } 
}

.fullscreenOnBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 9px;
  background-color: #FFFFFF;    
  cursor: pointer;  
  border-radius: 8px;
  &:hover {
    path {
      fill: #505050; 
    }
  }   
}

.block_full { 
  width: 100%;
  height: 100vh;  
  user-select: none;
  .withVideo {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
  }
  .videoBlock {
    width: 100%;
    flex-grow: 1;
    >div:last-child {
      height: 100%;
    }
    .videoRemote {
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
       
      border-radius: 0; 
      .video {
        position: absolute;
        bottom: 50%;
        width: 100%;                               
      } 
      .videoRemoteAvatar {
        width: 240px;
        height: 240px;
        margin-top: -120px;
        margin-left: -120px;
      }    
    }

    .mobile {
      margin-left: auto;
      margin-right: auto;  
      .video {
        position: relative;
        bottom: auto;
        margin-left: auto;
        margin-right: auto;  
        width: auto !important;  
        height: 100% !important;  
      }         
      video {
        display: block;
        height: 100% !important;
                           
      }           
    }
    .videoLocal {
      width: 280px;
      height: 166px;    
    }
  } 
  
  .controlBlock {
    background: rgba(230, 239, 251, 0.5);

    .fullscreenOnBtnContainer {
      min-width: 30%;
      >* {
        margin-left: auto;
      }
    }
    .textBlockContainer {
      min-width: 30%;
      >* {
        margin-right: auto;
      }
    }    
  }   
}

.transparent {
  opacity: 0;
}

.animatePulse {	
	animation: animate_pulse 1.5s ease-in-out infinite;
}

.animatePulseSmall {	
	animation: animate_pulse_small 1.5s ease-in-out infinite;
}

@keyframes animate_pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(66, 141, 255, 0.2);
	}
	50% {
		box-shadow: 0 0 20px 5px rgba(66, 141, 255, 0.8);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(66, 141, 255, 0.2);
	}
}

@keyframes animate_pulse_small {
	0% {
		box-shadow: 0 0 0 0 rgba(66, 141, 255, 1);
	}
	50% {
		box-shadow: 0 0 7px 2px rgba(66, 141, 255, 1);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(66, 141, 255, 1);
	}
}



