.nameItem {
  display: flex;
  margin-top: 16px;
  margin-bottom: 7px;
  >div:first-child {
    display: flex;
    flex-direction: column;
  }

  .fileName {   
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .fileSize {    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;  
  }

  .closeBtn {
    position: relative;
    display: block;    
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-right: 15px;
    z-index: 3;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.5s;    
    
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 11px;
      width: 25px;
      height: 2px;
      background-color: #000000;
      transform: rotate(-45deg) translate(-9px, 4px);
    }
    
    &::after {
      content: '';
      position: absolute;
      top: 28px;
      left: 10px;
      width: 25px;
      height: 2px;
      background-color: #000000;
      transform: rotate(45deg) translate(-8px, -4px);  
    }
  }
}