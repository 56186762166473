.side-menu {  
  a {
    display: flex;
    align-items: center;
  }

  .svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  .active {
    .svg {
      path {
        fill: #428dff;
      }
    }
  }        
}