.main-container {
  min-height: 100%; 
  height: auto;     
  overflow: auto;
}


.main-container::-webkit-scrollbar-track {
  background-color: rgba(0, 68, 9, 0.0)
}
  
.main-container::-webkit-scrollbar-thumb:hover{
  border: 1px solid transparent;
}



.main-container::-webkit-scrollbar{
  width: 8px;  
}

.main-container::-webkit-scrollbar-thumb {
  background-color: #828282;
  border: 2px solid transparent;
  background-clip: content-box;
  border-radius: 8px;  
}
