.button {
    border-radius: 8px;
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s;
    padding: 0;
    &:focus{
        outline: none !important;
    }
}

.md {
    width: 30px;
    height: 30px;
}

.lg {
    width: 40px;
    height: 40px;
}

.xl {
    width: 70px;
    height: 70px;
}

.primary {
    background: #D4E1F4;
    color: #428DFF;
    &:hover {
        background: #bdcadd;
    }
}

.warning {
    background: #FFF9DD;
    color: #FFD200;
    &:hover {
        background: #ded8bd;
    }
}

.danger {
    background: #FFE6E2;
    color: #EB5757;
    &:hover {
        background: #dbc4c0;
    }
}
