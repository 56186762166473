.button {    
    padding: 8px 24px;
    border-radius: 8px;
    background: transparent;
    outline: none;
    border: none;
    font-family: var(--font-main);
    font-size: 16px;
    text-transform: uppercase;
    transition: .2s;
    &:focus {
        outline: none !important;
    }
}

.outline {
    border: 1px solid #428DFF;
    color: #428DFF;
    background: transparent;
    &:hover,
    &:focus,
    &:active {
        border-color: #2174F2;
        color: #2174F2    ;
    }
    &.disabled {
        color: #A5A5A5;  
        border-color: #D6D6D6;  
    }   
}

.primary {    
    background-color: #428DFF;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
        background-color: #2174F2;             
    }
    &.disabled {
        color: #A5A5A5;  
        background-color: #D6D6D6;  
    }      
}

.secondary {
    background-color: #AAAAAA;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
        background-color: #505050;             
    }
    &.disabled {
        color: #A5A5A5;  
        background-color: #D6D6D6;  
    }     
}

.destructive {
    background: #EB5757;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
        background-color: #D73F3F;             
    } 
}

.outline-destructive {
    border: 1px solid #EB5757;
    color: #EB5757;
    background: transparent;
}

.stretched {
    width: 100%;
}
  
.disabled {    
    cursor: default !important;
}

.modified {
    min-width: 200px;
    padding: 10px;    
    font-size: 16px;
    line-height: 23px;
    font-weight: 700;
    text-transform: uppercase;
}