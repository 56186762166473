.logo {
  min-width: 173px;
  margin-right: 0 !important
}

.search {
  margin-right: 40px;
}

.profile {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.avatar {
  width: 50px;
  min-width: 50px;
  height: 50px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 29px;
    height: 37px;
  }
}

.btnBlock {
  padding-top: 5px;
  padding-right: 20px;
}

.photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px;
}
