.modal__container {
  width: 578px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #F7FAFC;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}

.modal__title {  
  font-size: 16px;  
  font-weight: 700;  
}

.modal__desc {
  margin-bottom: 28px;  
  font-size: 14px;  
  font-weight: 400;
  line-height: 18px;  

  a {
    color: #428DFF; 
  }
}

.modal__row {
  input,
  textarea {
    background-color: #ffffff;
  }
} 

.modal__close {
  position: relative;
  top: -10px;
  right: -10px;
  display: block;    
  width: 40px;
  height: 40px;
  background-color: #ffffff;    
  z-index: 3;
  border-radius: 7px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 11px;
    width: 24px;
    height: 2px;
    background-color: #000000;
    transform: rotate(-45deg) translate(-9px, 4px);
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 28px;
    left: 10px;
    width: 24px;
    height: 2px;
    background-color: #000000;
    transform: rotate(45deg) translate(-8px, -4px);  
  }
}

.modal__fileBtn {
  margin-bottom: 16px;
  label {
    display: flex;
    justify-content: center;
    width: 100%;    
    padding: 8px;
    border: 1px solid #428DFF;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    color: #428DFF;    
    font-style: normal;
    font-weight: 600;   
    transition: 0.5s;
    svg {
      margin-right: 9px;
      path {
        fill: #428DFF;
      }
    }

    &::after {
      content: 'Прикрепить фото';
    }
    
    &:hover,
    &:focus {
      color: #2174F2;
      border-color: #000000;
      svg {        
        path {
          fill: #2174F2;
        }
      }
    }
  }

  input[type='file'] {
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
  
    white-space: nowrap;
  
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  } 
}

.modal__submitBtn {
  display: flex;
  justify-content: center; 

  button {
    padding: 8px 45px; 
  }
}
