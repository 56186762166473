// Carousel
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';
//@import "../../node_modules/font-awesome/scss/font-awesome";
@import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

//import sidebar styles
@import '../common/sidebar/sidebar.scss';
@import '../layout/private.scss';

@function grayscale($value) {
    @return #{'grayscale(' + $value + ')'};
}
/*
 * Dashboard
 */

:root {
    --font-main: 'Montserrat', sans-serif;
    --aside-width: 190px;
    --header-height: 67px;
}

.page__offset {
    padding-top: 70px;
}

.page_main {
    background: #f7fafc;
    min-height: 100%;
    height: auto;
}

html {
    background: #ffffff;   
}

body {
    overflow: hidden
}

header {
    padding: 30px 0;
}

ol,
ul,
dl {
    margin-top: 1em;
}
.has-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.show > .dropdown-menu {
    display: block;
}
.btn-primary,
.bg-primary {
    background: #428dff !important;
}
.btn-primary {
    border-color: #428dff !important;
}
.App {
    display: flex;          
    align-items: stretch; 
    width: 100%;
    height: 100%;       
}


body {
    font-family: 'Poppins', sans-serif;
    height: 100vh;   
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 190px;
    max-width: 190px;
    background: #ffffff;
    color: #828282;
    margin-left: -170px;
    transition: all 0.5s;

    .infoBtn {
        position: absolute;
        bottom: 12px;
        left: 16px;          
        font-weight: 500;        
        cursor: pointer;
        transition: 0.5s;
        svg {
            margin-right: 14px;
        }
        &:hover,
        &:focus {
            color: #428DFF; 
            path {
                fill: #428DFF;
            }
    }
    }
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}
.sidebar .nav-item {
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
}
.sidebar .nav-item.active,
.sidebar .nav-item:hover {
    color: #428dff;
}
.nav-link:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 0px;
    background: #428dff;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out;
}

.sidebar .nav-item:hover .nav-link:before,
.sidebar .nav-item.active .nav-link:before {
    width: 5px;
}
.nav-link {
    position: relative;
}

.sidebar-header {
    background: #ffffff;
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.items-menu {
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 15px;
    top: 10%;
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
.side-menu {    
    height: calc(100vh - 130px);    
    .side-menu__link--systemAdmin {
        display: flex;
    }
    a:hover,
    a:focus {
        color: #428DFF; 
        path {
            fill: #428DFF;
        }
    }
}

/* .side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #428dff;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #428dff;
} */

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
}

@media only screen and (max-width: 500px) {
    // body {
    //   overflow:  hidden;
    // }

    .content.is-open {
        margin-left: 100%;
    }

    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }

    .sidebar.is-open > .sidebar-header span {
        display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}
html {
    position: relative;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: black;
}

h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
}
h2 {
    margin: 0;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
}

p {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 23px;
    color: #555555;
}

.submit::after {
    display: table;
    clear: both;
    content: '';
}
.submit {
    padding-top: 30px;
}
.submit h2 {
    margin: 0;
    font-size: 16px;
    color: #eb5757;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-family: Montserrat, sans-serif;
}
.submit h2:hover {
    color: red;
}

.login-form {
    display: inline-block;
}

.login-form td {
    text-align: left;
    color: gray;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    font-family: Montserrat, sans-serif;
}

.login-form input[type='text'],
.login-form input[type='password'] {
    background: #f7fafc;
    border: 1px solid #aaaaaa;
    box-sizing: border-box;
    border-radius: 8px;
    width: 356px;
    padding: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: black;
    font-family: Montserrat, sans-serif;
}

.login-form input[type='text']:hover,
.login-form input[type='password']:hover {
    border: 2px solid #428dff;
}

.login-form input[type='text'] {
    margin-bottom: 10px;
    color: black;
}

.login-menu::after {
    display: table;
    clear: both;
    content: '';
}

.login-menu {
    padding-top: 30px;
    padding-left: 150px;
    padding-right: 150px;
}

.login-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.login-menu li {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    font-family: Trebuchet MS Bold, sans-serif;
    border-bottom: 1px solid #828282;
}

.login-menu .NOW-ACTIVE {
    border-bottom: 1px solid #428dff;
    color: #428dff;
}

.login-menu a {
    color: gray;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    font-family: Montserrat, sans-serif;

    color: #828282;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 3px;
    &:hover {
        border-bottom-color:#428dff;
    }
}

.login-menu a:hover {
    text-decoration: none;
    color: #428dff;
}

.login-menu a:active {
    text-decoration: none;
    opacity: 0.3;
}
.login-footer {
    margin-top: 20px;
    margin-bottom: 0px;
}

.login-footer ul {
    list-style: none;
}

.login-footer li {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    font-size: 18px;
    font-family: Montserrat, sans-serif;
}

.login-footer a,
.login-footer span {
    font-size: 12px;
    color: gray;
    text-decoration: none;
}

.login-footer a:hover {
    text-decoration: underline;
}

.login-footer a:active {
    text-decoration: none;
    opacity: 0.3;
}

.footer-people-login {
    text-align: center;
    position: static;
    vertical-align: bottom;
    margin-bottom: 0;
}

.page::after {
    display: table;
    clear: both;
    content: '';
}
.page {
    position: relative;

    z-index: 100;
    vertical-align: bottom;
    min-height: 100%;
    bottom: 0;
    min-width: 100%;
}
.widget {
    padding: 0;
    background: white;
    position: fixed;
    min-width: 170px;
    padding-top: 20px;
    left: 0px;
    top: 0;
    bottom: 0px;
    background: white;
    margin-right: 200px;
}
.widget h3 {
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    color: #828282;
}
.widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 10px;
}
.widget li {
    padding-left: 17px;
    padding-right: 10px;
}

.widget li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.widget a {
    text-decoration: none;
    display: inline-block;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    color: #828282;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.icon-full:hover {
    border-left: 5px solid #428dff;
    border-radius: 3px;
}

.icon {
    filter: grayscale(100%);
}

.icon-full:hover .icon,
.icon-full:hover a {
    color: #428dff;
    filter: grayscale(0);
}
.icon-foot {
    position: absolute;
    vertical-align: bottom;
    bottom: 15px;
    font-size: 12px;
}
.icon-foot h3 {
    font-size: 15px;
}
.widget .NOW-ACTIVE {
    border-left: 5px solid #428dff;
    border-radius: 3px;
}

.NOW-ACTIVE .icon,
.NOW-ACTIVE a {
    color: #428dff;
    filter: grayscale(0);
}

.main-header::after {
    display: table;
    clear: both;
    content: '';
}

.main-header {
    list-style: none;
    position: fixed;
    left: 0px;
    top: 0;
    right: 0px;
    background: white;
    margin-left: 160px;
}

.main-header .main-header-content {
    list-style: none;
    margin: 0;
    min-width: 650px;
}

.main-header .pun {
    display: inline-block;

    float: right;
    vertical-align: middle;
    padding-right: 10px;
}

.main-header .pun:first-child {
    float: left;
    background: url('/img/find.png') no-repeat left bottom;
    padding-left: 40px;
    padding-top: 10px;
}

.main-header input[type='text']:hover {
    border: 1px solid #428dff;
    border-radius: 15px;
}

.main-header .pun:nth-child(2) {
    padding-left: 20px;
    padding-top: 10px;
}
.main-header input[type='text'],
.main-header input[type='password'] {
    border: 0;
    padding: 7px;
    padding-right: 50px;
}

.topmenu {
    position: relative;
    text-align: left;
    background: url('/img/arrow.png') no-repeat right center;
    font-size: 14px;
    color: #828282;
    width: 230px;
    padding: 15px;
}

.submenu {
    margin: 0;
    padding: 0;
    position: absolute;
    right: -20px;
    top: 50px;
    background: white;
    border-radius: 8px;
    list-style: none;
    visibility: hidden;
}
.submenu li {
    padding-right: 15px;
    padding-left: 13px;
    padding-bottom: 5px;
}

.myBtn {
    padding-right: 10px;
    border: none;
    background: white;
    width: 250px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-top: 5px;
    font-size: 14px;
    color: #828282;
    text-align: left;
}
.myBtn:hover,
.myBtn:active {
    color: white;
    background: #428dff;
    border-radius: 8px;
}

.modal,
.eddModal,
.logModal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-backdrop {
    z-index: 1030;
}

.modal-content {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    position: inherit;
    right: 45px;
    top: 120px;
    color: #428dff;
    font-weight: bold;
}
.modal-content h3 {
    color: #2e1747;
    text-align: center;
}

.modal-content td {
    font-style: normal;
    font-weight: normal;
    padding-top: 16px;
}

.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container input {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    display: block;
}
.save:hover {
    background: #428dff;
    color: #ffffff;
}

.close.btn:hover,
.close:focus {
    background-color: #aeaeae !important;
    cursor: pointer;
}

.topmenu:hover .submenu {
    visibility: visible;
}

.navigator::after {
    display: table;
    clear: both;
    content: '';
}
.navigator {
    position: inherit;
    margin-top: 55px;
    padding-left: 5px;
    min-width: 500px;
    line-height: 19px;
    display: inline-block;
}

.navigator ul {
    list-style: none;
    padding: 0;
}

.navigator li {
    display: inline-block;
    padding-right: 4px;
    padding-left: 4px;
    vertical-align: top;
    margin: 0;
}

.navigator a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: black;
}
.navigator li:last-child a {
    color: #428dff;
}
.navigator h3 {
    margin-left: 5px;
    font-size: 16px;
}
.adm-main-page::after {
    display: table;
    clear: both;
    content: '';
}
.adm-main-page h3 {
    position: absolute;
}
.adm-main-page {
    position: relative;
    background-color: white;
    color: black;
    position: inherit;
    min-width: 1040px;
    border-radius: 8px;
    z-index: 1;
    min-height: 250px;    
}

.adm-main-page .edit {
    position: absolute;
    top: 15px;
    right: 15px;
    
}
.edit a {
    font-size: 14px;
    color: #828282;
    text-decoration: none;
    vertical-align: top;
}
.edit a:hover {
    text-decoration: underline;
}

.adm-main-page .edit img {
    padding-right: 10px;
}
.emblem {
    float: left;
    padding: 50px;
}
.load {
    position: absolute;
    z-index: 0;
    left: 290px;
    top: 230px;
}
.waste {
    position: absolute;
    z-index: 0;
    left: 335px;
    top: 235px;
}
.edit-adm-main-page .emblem {
    float: left;
    padding: 20px;
    border: 2px solid lightgrey;
}

.main-data-table {
    text-align: left;
    padding: 0px;
    padding-bottom: 15px;
}

.edit-data-table {
    margin-top: 25px;
}
.edit-data-table td {
    border-bottom: 1px solid #828282;
    padding: -10;
}
.edit-data-table td input[type='text'] {
    border: none;
    min-width: 300px;
    font-size: 16px;
    color: black;
}
.edit-data-table td:hover {
    border-bottom: 1px solid #eb5757;
}
.edit-data-table td li {
    font-weight: bold;
}
.edit-data-table td:hover li {
    color: #eb5757;
    font-weight: bold;
}
.long {
    width: 600px;
}
.main-data-table tr {
    min-width: 300px;
}
.main-data-table td {
    border-bottom: 1px solid #828282;
    padding: 0;
}
tr .cansel-save,
.edit-data-table td:last-child {
    border-bottom: none;
}
tr .cansel-save:hover,
.edit-data-table td:last-child:hover {
    border-bottom: none;
}

.main-data-table ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 19px;
}
.main-data-table li {
    list-style: none;
    min-width: 160px;
    padding-bottom: 3px;
}

.column {
    width: 310px;
}

.first {
    font-size: 14px;
    color: #828282;
}

.red {
    color: #eb5757;
    padding-left: 5px;
}
.green {
    color: darkslategray;
    padding-left: 5px;
}
.blue {
    color: #428dff;
}
.patient-info {
    vertical-align: top;
}
.patient-info:first-child li {
    color: #828282;
    font-weight: bold;
    font-family: Montserrat, sans-serif;
    padding: 0;
    margin: 0;
}
.patient-name a:hover {
    color: #428dff;
    text-decoration: none;
}
.patient-name a {
    text-decoration: none;
    color: #828282;
    font-family: Montserrat, sans-serif;
}
.patient-info td {
    padding-right: 0px;
    min-width: 200px;
    vertical-align: middle;
    text-align: left;
}
.patient-info td:last-child {
    width: 70px;
    padding-right: 0px;
}

.patient-info:first-child {
    background-color: #f7fafc;
    position: inherit;
    border-radius: 8px;
    z-index: 1;
    margin-bottom: 0px;
    min-height: 20px;
    margin-top: 0;
}
.patient-personal-info {
    list-style: none;
}

.patient-personal-info li {
    list-style: none;
}

.patient-personal::after {
    display: table;
    clear: both;
    content: '';
}
.patient-contracts::after {
    display: table;
    clear: both;
    content: '';
}

.patient-personal {
}
.full-info {
    padding-right: 20px;
}
.patient-contracts .login-menu {
    padding: 0;
}
.patient-contracts div {
    padding-left: 30px;
}
.patient-personal-info .second {
    border-bottom: 1px solid lightgray;
    margin-bottom: 15px;
}

.patient-contract .patient-info td {
    min-width: 150px;
    padding: 20px;
}
.patient-contract::after {
    display: table;
    clear: both;
    content: '';
}
.patient-contract .patient-info td:nth-child(2) {
    min-width: 400px;
}
.patient-contract .patient-info td:nth-child(1) {
    min-width: 80px;
}
.light {
    font-weight: 100;
    color: black;
}
.light-archive {
    font-weight: 100;
    color: #828282;
}
.send-modal {
    display: none;
    position: fixed;
    z-index: 1;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.send-modal-content {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    width: 200px;
    position: inherit;
    right: 65px;
    top: 260px;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
}
.send-modal-content p {
    color: #428dff;
}
.send-btn {
    border: none;
    background: none;
}
.table {
    width: auto;
}
main {
    /*padding: 1.5rem;
  background: #F7FAFC;*/
}
.navbar-dark .navbar-nav .nav-link {
    color: #828282;
    font-size: 14px;
}
.navbar-dark .navbar-nav .nav-link::after {
    color: #428dff;
    margin-left: 25px;
}
.dropdown-menu {
    border: none;
    box-shadow: 0px 5px 15px #0000001a;
}
.dropdown-menu .dropdown-item {
    font-size: 14px;
    color: #828282;
    background: white;
    transition: 0.3s ease-in-out;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item.active {
    color: white;
    background: #428dff;
    border-radius: 8px;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: #428dff;
}
.patient-info {
    vertical-align: top;
}
.patient-list ul,
.patient-contract ul,
.contract-history ul,
.setting-types ul {
    background-color: white;
    color: black;
    position: inherit;
    border-radius: 8px;
    z-index: 1;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 1100px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.patient-list ul,
.patient-contract ul,
.contract-history ul,
.setting-types ul {
    background-color: white;
    color: black;
    position: inherit;
    border-radius: 8px;
    z-index: 1;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 1100px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.patient-info:first-child {
    background-color: #f7fafc;
    position: inherit;
    border-radius: 8px;
    z-index: 1;
    margin-bottom: 0px;
    margin-top: 0;
    color: #828282;
}
.patient-info li:first-child {
    min-width: 350px;
    padding: 0px;
    padding-right: 20px;
    flex-shrink: 3;
}
.patient-info:first-child li {
    color: #828282;
    font-weight: bold;
    font-family: Montserrat, sans-serif;
    padding: 0;
    margin: 0;
}
.patient-list ul,
.patient-contract ul,
.contract-history ul,
.setting-types ul {
    background-color: white;
    color: black;
    position: inherit;
    border-radius: 8px;
    z-index: 1;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 1100px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.patient-info li {
    list-style: none;
    display: inline-block;
    padding: 0px;
    min-width: 245px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    font-family: Montserrat Light, sans-serif;
    box-sizing: border-box;
    vertical-align: middle;
    padding: 15px;
    padding-left: 0;
    font-weight: bold;
    color: #828282;
    flex: auto;
    flex-shrink: 2;
    flex-basis: 245px;
}
.light li {
    color: black;
}
.patient-info li:first-child {
    min-width: 350px;
    padding: 0px;
    padding-right: 20px;
    flex-shrink: 3;
}
.img-btn {
    background: none;
    text-align: center;
    vertical-align: middle;
    border-radius: 8px;
    border: none;
    padding: 0;
    border: 1px solid white;
    margin-right: 10px;
}
.img-btn:hover {
    border: 1px solid #428dff;
    border-radius: 8px;
}
.main-contracts .login-menu {
    padding-left: 0;
    padding-top: 0;
}
.modal-open .modal {
    z-index: 1031;
}
.Toastify__toast-container {
    z-index: 1032;
}
.react-datepicker-wrapper input {
    width: 100% !important;
}
.invalid-feedback {
    display: block;    
}
.login-menu li {
    border-bottom: none;
}
.login-menu a {
    border-bottom: 1px solid #828282;
}
.doctor-info li {
    list-style: none;
    display: inline-block;
    padding: 10px 0px;
    min-width: 145px;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    font-family: Montserrat Light, sans-serif;
    box-sizing: border-box;
    vertical-align: middle;
    font-weight: 600;
    text-decoration: none;
}
.patient-contract .patient-info li:nth-child(1) {
    min-width: 80px;
}

#ModalAddDoctor .modal-content {
    width: 700px;
}
.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}
.edit-data-table .cansel-save a,
.edit-data-table .cansel-save button {
    margin: 0 1rem 0 0;
}
.edit-data-table .cansel-save {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: row-reverse;
    padding: 1rem;
    margin: 0;
}
.edit-data-table .cansel-save {
}
.edit-data-table .first {
    width: 300px;
}
.personal-information {
    margin-top: 10px;
    margin-left: 50px;
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
}
.grid {
    display: grid;
    grid-gap: 2px;
    margin: 15px;
}
.doc-foto {
    width: 220px;
}
.auto-fit {
    grid-template-columns: repeat(auto-fit, minmax(700px, 40%));
    border-bottom: 1px solid #aaaaaa;
    color: #aaaaaa;
}
.auto-fill {
    grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
}
.personal-information-edit .first div {
    padding-top: 5px;
}
.personal-information input[type='text'],
.personal-information input[type='date'] {
    border: 1px solid #828282;
    border-radius: 8px;
    padding: 10px;
    width: 400px;
    margin-bottom: 20px;
    background: #f7fafc;
    outline: none;
}
.dzu-dropzone {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 1rem;
    border: 2px dotted #d9d9d9 !important;
    border-radius: 5px !important;
}
.dzu-inputLabel {
    padding: 1rem;
}
.dzu-previewContainer {
    flex-direction: column !important;
    border-bottom: none;
}
.dzu-previewImage {
    margin-bottom: 1rem;
    width: 100% !important;
    max-width: 100% !important;
    max-height: initial !important;
}
.dzu-previewStatusContainer {
    width: 90%;
    display: flex;
}
.dzu-previewStatusContainer progress {
    width: 100%;
}
.dzu-inputLabel {
    font-size: 16px;
    font-weight: 600;
    color: #428dff;
    text-align: center;
    font-family: Montserrat, sans-serif !important;
}
.dzu-previewButton {
    width: 17px !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    margin-left: 0.5rem;
    cursor: pointer;
}
.dzu-previewContainer {
    margin-bottom: 1.5rem;
}
.tab-content {
    padding-top: 1rem;
}
.new-contract span input {
    width: 100%;
}
.switcher {
    padding-top: 15px;
    text-align: left;
    display: inline-block;
    float: left;
    margin-right: 0px;
    padding-left: 0;
    padding-right: 30px;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 32px;
    padding: 0;
}
.switch input {
    display: none;
}
.slider.round {
    border-radius: 32px;
    margin: 0;
}
.slider.round:before {
    border-radius: 50%;
}
.slider:before {
    position: absolute;
    content: '';
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1);
}
.switcher .blue,
.small {
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: normal;
}
.blue {
    color: #428dff;
}
input:checked + .slider {
    background-color: #428DFF;
}
input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
img {
    max-width: 100%;
}
.patient-list ul,
.patient-contract ul,
.contract-history ul,
.setting-types ul {
    background-color: white;
    color: black;
    position: inherit;
    border-radius: 8px;
    z-index: 1;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 1100px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.setting-type-info:first-child {
    background-color: #f7fafc;
    position: inherit;
    border-radius: 8px;
    z-index: 1;
    margin-bottom: 0px;
    margin-top: 0;
    color: #828282;
}
.setting-types li {
    list-style: none;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    font-family: Montserrat, sans-serif;
    box-sizing: border-box;
    vertical-align: middle;
    color: #828282;
    padding: 10px;
    margin: 0;
    min-width: 120px;
}
.setting-types .light li {
    color: black;
}
.navigator li:before {
    content: '/';
    margin-right: 0.5rem;
}
.navigator li:first-child:before {
    content: '';
    margin-right: 0;
}
.navigator {
    margin: 0;
    width: 100%;
}
.setting-types li:nth-child(2) {
    min-width: 910px;
}
.add-settings .button {
    background: #428dff;
    color: white;
    border: 0;
    padding: 10px;
    box-shadow: none;
    margin-top: -40px;
    margin-right: 13px;
    font-size: 16px;
    z-index: 10;
    display: inline-block;
    float: right;
    width: 150px;
}
.switch .slider.round {
    width: 100%;
}
.contract-close .save {
    color: #eb5757;
    border: 1px solid #eb5757;
    background: #ffffff;
}
.add-settings {
    position: relative;
    z-index: 2;
}
.add-settings .button:hover {
    color: #ffffff !important;
}

// ---------------------------------------------------
// Carousel
// _____________________________________________________
.slick-dots{
    height: 70px;
    width:100%;
    bottom: -80px;


}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 4px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button:before {
    opacity: 1;
    color: #575757;
}


.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #428DFF;
}

button.disabledBtn {
  background-color: #E0E0E0; 
  opacity: 1;
  &:hover {
    opacity: 1;
    background-color: #E0E0E0;
  }

}

.body-overflow-landing {
   height: 100%;
   touch-action: none;
-ms-touch-action: none;
}