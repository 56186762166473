.label {
    display: flex;
    margin-bottom: 0;
}

.required {
    color: red;
    margin-left: 5px;
}

.top {
    font-family: var(--font-main);
    font-weight: 600;
    margin-bottom: 4px;
    color: #737373;
    font-size: 14px;
    white-space: nowrap;
}

.block {
    display: flex;
    flex-direction: column;
}

.input {
    background: #f7fafc;
    border: 1px solid #aaaaaa;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: black;
    font-family: var(--font-main);
    max-height: 300px;
    min-height: 70px;
    &:focus {
        outline: none;
    }
    &:disabled {
        background: #eee !important;
        border-color: #eee ;
        &:hover {
            border-color: #eee !important;
        }
    }
}

.bottom {
    min-height: 14px;
    margin-top: 2px;
    font-family: var(--font-main);    
    color: #dc3545;
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
}



