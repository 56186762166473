.modalBody {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 256px;
  height: 40px;
  box-sizing: border-box;
  z-index: 5;
}

.on {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #428DFF;  
}


.of {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #EB5757;
}



